







































import { Component, Ref, Vue, Watch } from 'vue-property-decorator'
import { v4 as uuidv4 } from 'uuid'
import { FindNumerosInutilizaveisUseCase, InutilizaNumerosUseCase, SerieENumeroNfParam } from '@/usecases'
import { SerieENumeroNF, SerieInutilizada } from '@/models'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import { DataOptions } from 'vuetify'
import axios, { CancelTokenSource } from 'axios'

@Component({})
export default class DialogoDeManutencaoDeVendas extends Vue {
	@Ref() form!: HTMLFormElement
	salvando = false
	mostra = false
	carregando = true
	findNumerosInutilizaveisUseCase = new FindNumerosInutilizaveisUseCase()
	inutilizaNumerosUseCase = new InutilizaNumerosUseCase()
	seriesInutilizaveis: SerieENumeroNF[] = []

	paginacao: DataOptions = {
		page: 0,
		itemsPerPage: 10,
		sortBy: [],
		sortDesc: [],
		groupBy: [],
		groupDesc: [],
		multiSort: false,
		mustSort: false,
	}

	totalDeRegistros = -1

	headers = [
		{ text: 'Série', value: 'serie', sortable: false, align: 'center' },
		{ text: 'Nº', value: 'numero', sortable: false, align: 'center' },
		{ text: 'Loja', value: 'loja.nomeFantasia', sortable: false, align: 'center' },
		{ text: 'Ambiente', value: 'ambiente', sortable: false, align: 'center' },
	]

	headersComErro = [
		{ text: 'Série', value: 'serie', sortable: false, align: 'center' },
		{ text: 'Nº', value: 'numero', sortable: false, align: 'center' },
		{ text: 'Loja', value: 'loja.nomeFantasia', sortable: false, align: 'center' },
		{ text: 'Ambiente', value: 'ambiente', sortable: false, align: 'center' },
		{ text: 'Cod. Status', value: 'codigoDeStatus', sortable: false, align: 'center' },
	]

	cancelToken: CancelTokenSource | null = null
	selected: SerieENumeroNF[] = []
	seriesNaoInutilizadas: SerieInutilizada[] = []

	itens = [
		{ id: '1', serie: '1', numero: 10, loja: 'Teste', ambiente: 'Homologação' },
		{ id: '2', serie: '2', numero: 10, loja: 'Teste', ambiente: 'Homologação' },
	]

	get series() {
		if (!this.seriesInutilizaveis) return []
		return this.seriesInutilizaveis.map(serie => ({
			...serie,
			id: uuidv4(),
		}))
	}

	async inutilizar() {
		try {
			this.carregando = true
			this.seriesNaoInutilizadas = []
			const seriesAInutilizar = this.selected
				.map<SerieENumeroNfParam>(selecionado => ({
					...selecionado,
					idDaLoja: selecionado.loja.id,
				}))

			const numerosNaoInutilizados = await this.inutilizaNumerosUseCase.execute(seriesAInutilizar)
			if (numerosNaoInutilizados.length) {
				this.seriesNaoInutilizadas = numerosNaoInutilizados
				AlertModule.setError('Algumas series não foram inutilizadas')
			}
			await this.buscar()
		} catch (e) {
			AlertModule.setError(e)
			this.carregando = false
		}
	}

	async mostrar() {
		this.mostra = true
		await this.buscar()
	}

	async buscar() {
		try {
			this.carregando = true
			if (this.cancelToken) this.cancelToken.cancel()
			this.cancelToken = axios.CancelToken.source()
			this.seriesInutilizaveis = []
			const series = await this.findNumerosInutilizaveisUseCase.find({
				page: this.paginacao.page - 1 || 0,
				size: this.paginacao.itemsPerPage || 5,
			}, { cancelToken: this.cancelToken.token })

			this.seriesInutilizaveis = series.content
			this.totalDeRegistros = series.totalElements
			this.carregando = false
		}	catch (e) {
			if (axios.isCancel(e)) return
			this.carregando = false
			AlertModule.setError(e)
		}
	}

	@Watch('paginacao')
	onChangePaginacao() {
		this.seriesInutilizaveis = []
		this.buscar()
	}

	esconder() {
		this.mostra = false
		this.selected = []
		this.seriesInutilizaveis = []
	}
}

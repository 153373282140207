




















import { Component, Prop, Ref, Vue } from 'vue-property-decorator'
import DialogoDeManutencaoDeVendas from '@/views/application/venda/DialogoDeManutencaoDeVendas.vue'
import BotaoDeDrawer from '@/components/ui/BotaoDeDrawer.vue'
import IconesDaToolbarPadroes from '@/components/layout/IconesDaToolbarPadroes.vue'

@Component({
	components: {
		BotaoDeDrawer,
		IconesDaToolbarPadroes,
		DialogoDeManutencaoDeVendas,
	},
})
export default class ToolbarDeVendas extends Vue  {
	@Ref() dialogoDialogoDeManutencao!: DialogoDeManutencaoDeVendas
	@Prop({ type: String, default: '' }) titulo!: string

	mostraDialogoDeManutencao() {
		this.dialogoDialogoDeManutencao.mostrar()
	}
}
